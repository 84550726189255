import React, { Component } from "react";
import { Link } from "react-router-dom";
import Branding from "../../Services/Branding";
import CustomImg from "../Global/CustomImg/CustomImg";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      open: false,
      currentPage: "",
      logoImageUrl: null,
      logoImageAlt: null
    };
    this.setAnchorEl = this.setAnchorEl.bind(this);
  }

  async setBranding() {
    const branding = await Branding.getBranding();
    if (this.state.logoImageUrl !== branding.logoImageUrl) {
      this.setState({ logoImageUrl: branding.logoImageUrl });
      this.setState({ logoImageAlt: branding.logoImageAlt });
    }
  }

  async componentDidMount() {
    await this.setBranding();

    let currentPage = this.props.location.pathname;
    this.setState({ currentPage: currentPage.replace("/", "") });
  }

  async componentDidUpdate(prevProps) {
    await this.setBranding();

    if (prevProps.location.pathname !== this.props.location.pathname) {
      let currentPage = this.props.location.pathname;
      this.setState({ currentPage: currentPage.replace("/", "") });
    }
  }

  setAnchorEl(value) {
    this.setState({
      anchorEl: value,
      open: !this.state.open,
    });
  }

  closeMenu = () => {
    this.setAnchorEl(null);
  };
  
  render() {
    return (
      <React.Fragment>
        <header className="bg-white sticky-top py-2 py-lg-3">
          <div className="container-fluid container-padding">
            <div className="d-flex align-items-center flex-wrap position-relative">
              <div className="header-left flex-grow-1">
                <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center">
                  <div className="header-logo">
                    <Link to={"/"}>
                      <CustomImg
                        src={this.state.logoImageUrl}
                        className="img-fluid"
                        alt={this.state.logoImageAlt}
                        longdesc={this.state.logoImageAlt}
                        title={this.state.logoImageAlt}
                      />
                    </Link>
                  </div>
                  <div className="d-none d-lg-block">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Header;
