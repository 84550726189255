import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from '../../../Components/Layout/Header';

const mapStateToProps = state => {
    // console.log("Header TaskReducer ",TaskReducer);        
    return {
    };
};

const mapDispatchToProps = dispatch => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));