// import {Auth} from 'aws-amplify';

const brands = {
    sportsmed: {
        imageUrl: "sportsmed-logo.svg",
        imageAlt: "SportsMed Connect"
    },
    urgentcare: {
        imageUrl: "sportsmed-logo.svg",
        imageAlt: "Urgent Care Clinic"
    }
};
brands.default = brands.sportsmed;

export default class Branding {
    logoImageUrl = null;

    static async getBrandingFromUser(user) {
        if (user.attributes && user.attributes["custom:Practice"]) {
            return brands[user.attributes["custom:Practice"].toLowerCase()];
        }
        return null;
    }

    static async getBrandingFromConference(appointment) {
        if (appointment && appointment.clinic) {
            return brands[appointment.clinic.toLowerCase()];
        }
    }

    static async getBrandingFromUrl() {
        const m = window.location.pathname.match(/\/auth\/(.*)$/i);
        if (m) {
            return brands[m[1].toLowerCase()];
        }
        return null;
    }

    static async getBranding() {
        const b = new Branding();
        let brand = null;

        /* if (!brand) {
            const x = localStorage.getItem("CURRENT_BRAND");
            if (x) {
                try {
                    brand = JSON.parse(x);
                } catch (e) {
                }
            }
        } */

        if (!brand) {
            brand = brands.default;
        }

        // localStorage.setItem("CURRENT_BRAND", JSON.stringify(brand));

        b.logoImageUrl = brand.imageUrl;
        b.logoImageAlt = brand.imageAlt;

        return b;
    }
}
