import { createStore, applyMiddleware, compose } from "redux";
import rootReducers from "../../src/store/reducers";
import thunk from "redux-thunk";

let middleware = undefined;
if (process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");
  middleware = applyMiddleware(thunk, logger);
} else {
  middleware = applyMiddleware(thunk);
}

const enhancer = compose(middleware);

const store = createStore(rootReducers, enhancer);

export default store;
