import React, { Component } from 'react';

class Footer extends Component {

    render() {
      
        return (<React.Fragment>
            <div className="bottom-section d-flex">                
            </div >
        </React.Fragment >);
    }
}

export default Footer;