import { IS_LOADING } from '../actions/actionTypes';
import { combineReducers } from 'redux';


const INITIAL_STATE = false;

const loadingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IS_LOADING:
      return action.isLoading;

    default:
      return state;
  }
};


export default combineReducers({
  isLoading: loadingReducer
});
