import React, { Component } from 'react';
import IframeResizer from 'iframe-resizer-react';

class UrgentCare extends Component {
  render() {
    return (
        <React.Fragment>
            <main className="main-container">
                <div className="content-area scrollbar h-100">
                    <h1 className='text-center' style={{ fontSize: "30px"}}><strong>Request an Appointment</strong></h1>
                    <div className="container-fluid container-padding">
                        <IframeResizer
                            loading="lazy"
                            width="100%" height="500" 
                            src="https://practisforms.com/sportsmedalabama/forms/appointment-request-form-237894"
                            style={{ border:0, overflow:"scroll" }}
                            title="secure online form"
                        />
                    </div>
                    <div className="footer nunito-regular text-center" style={{ fontSize: "18px", color: "#7b7b7b"}}>
                        A <span className="theos nunito-bold">Theos</span>
                        <span className="health nunito-bold">Health</span> Partner
                    </div>
                </div>
            </main>
            
        </React.Fragment>
    )
  }
}


export default UrgentCare;
